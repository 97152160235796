body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: black;
  color: white;
}

.top-left {
  position: fixed;
  left: 0px;
  top: 0px;
  padding: 20px;
}

.top-right {
  position: fixed;
  right: 0px;
  top: 0px;
  padding: 20px;
}

.bottom-right {
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 20px;
}

.bottom-left {
  position: fixed;
  left: 0px;
  bottom: 0px;
  padding: 20px;
}

.bottom-center {
  position: fixed;
  text-align: center;
  width: 100%;
  bottom: 0px;
  padding: 20px 0px 20px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  background: black;
  border: none;
  color: white;
}

.music-button {
  margin: 12px;
}

.nameContainer {
  text-align: center;
  padding: 10px 20px;
  line-height: 1.7;
}

.name {
  font-size: 24px;
  font-family: "Libre Baskerville";
  text-transform: uppercase;
}

.contact {
  font-size: 15px;
  color: rgb(167, 163, 167);
  font-weight: 400;
}

.workItem {
  padding-bottom: 20px;
}

.workItemContainer {
  padding: 20px 0px;
}

.page-content {
  padding: 20px 0px;
}

.workImage {
  width: 40px;
  vertical-align: middle;
  margin-right: 26px;
  border-radius: 6px;
}

.workInfo {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
}

.workName {
  font-weight: 500;
  font-size: 18px;
  vertical-align: middle;
}

.workDates {
  color: rgb(167, 163, 167);
  font-size: 15px;
}

.workDescription {
  font-size: 15px;
}

.photo {
  margin-bottom: 13px;
  max-width: 400px;
  border-radius: 8px;
}

.mainContainer {
  margin: auto;
  width: 100%;
  max-width: 440px;
}

.main {
  padding: 20px;
}

a {
  color: white;
  text-decoration: none;
}